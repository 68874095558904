<template lang="pug">
    div(class="ir-overview-main-regular")
        div(class="main-element" style="max-width:1000px; border:0px solid;")
            v-container(fluid id="kulijul" class="first-step row-block" style="border:0px solid red;")
                v-row(class="pb-3" style="border:0px solid;")
                    v-col(cols="9" class="pa-0" style="border:0px solid;")
                        v-container(fluid style="border:0px solid;")
                            v-row(class="pr-8" style="border:0px solid;")
                                v-col(cols="12" class="pa-0" style="border:0px solid;")
                                    div(class="mb-1" style="border:0px solid;")
                                        h2 {{ getCompany.pitch_title }}
                                    div(style="min-height:50px; margin-bottom:15px; border:0px solid;")
                                        div {{ getCompany.pitch_detail }}
                                v-col(cols="6" class="pl-0 pt-0 pb-0 pr-4" style="height:170px; border:0px solid green;")
                                    iframe(:src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                v-col(cols="6" class="p1-4 pt-0 pb-0 pr-0" style="height:170px; border:0px solid green;")
                                    iframe(:src="`https://www.youtube.com/embed/${getCompany.video_url_2}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                    v-col(id="right-regular-elements" cols="3" class="pa-0" style="border:0px solid;")
                        div(style="position:relative; height:100%; overflow:hidden; border:0px solid;")
                            img(@click="openGenericPage(getCompany.page_id)" :src="getCompany.image" style="position:absolute; bottom:0; width:100%; cursor:pointer; border:0px solid;")

            v-container(fluid id="kulijul2" class="second-step row-block" style="border:0px solid green;")
                v-row(style="border:0px solid;")
                    v-col(cols="12" class="pa-0" style="border:0px solid;")
                        v-container(fluid style="border:0px solid;")
                            v-row(style="border:0px solid;")
                                v-col(cols="12" class="pa-0 mb-1")
                                    div(class="mb-1" style="border:0px solid;")
                                        h2 {{ getCompany.name }} - {{ getCompany.pitch_title }}
                                    div(style="min-height:50px; margin-bottom:10px; border:0px solid;")
                                        div {{ getCompany.pitch_detail }}
                                template(v-if="getCompany.video_url_2 !== ''")
                                    v-col(cols="4" class="pl-0 pt-0 pb-0 pr-1" style="height:calc(20vw - 5px); border:0px solid green;")
                                        iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                        img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")
                                    v-col(cols="4" class="pl-1 pt-0 pb-0 pr-0" style="height:calc(20vw - 5px); border:0px solid green;")
                                        iframe(v-if="getCompany.video_url_2.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_2}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                        img(v-else :src="getCompany.video_url_2" style="height:100%; width:100%;")
                                template(v-else)
                                    v-col(cols="8" class="pa-0" style="height:calc(20vw - 5px); border:0px solid green;")
                                        iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                        img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")
                                v-col(cols="4" class="pl-1 pt-0 pb-0 pr-0" style="overflow:hidden;")
                                    img(@click="openGenericPage(getCompany.page_id)" :src="getCompany.image" style="height:19.4vw; cursor:pointer; border:0px solid;")

            v-container(fluid class="third-step row-block mt-3 mb-2" style="overflow:hidden; border:0px solid;")
                v-row(class="pa-2" style="height:410px;")
                    v-col(class="pa-0" cols="12" sm="6" md="8" lg="8" xl="8" style="background-color:#FFFFFF; border:0px solid;")
                        div(id="left-regular-chart-element" style="overflow:hidden; width:100%; height:100%; border:0px solid;")
                            ChartPrice(
                                v-if="showMe"
                                :legendView="false"
                                :single="true"
                                :leftToolbar="false"
                                :showrightSideBarLine="false"
                                :width="width - 3"
                                :height="height - 30"
                                page="main"
                                style="border:0px solid red;"
                                ref="chartPrice"
                            )

                    v-col(class="pa-0" cols="12" sm="6" md="4" lg="4" xl="4" style="border:0px solid red;")
                        div(class="right-menu" style="position:relative; height:100%; border:0px solid green;")
                            div(class="text-right" style="border:0px solid;")
                                v-btn(@click="openEdgerFinance()" small class="pl-1 pr-2")
                                    v-icon(size="15") mdi-eye-outline
                                    span(class="ml-1") {{ $t('edger_module_add_to_watchlist_on_edger_finance') }}
                            div(style="height:10px; border:0px solid;")
                            div(style="border:0px solid;")
                                v-container(fluid)
                                    v-row(style="height:370px; border:0px solid green;")
                                        v-col(v-for="kpi in kpis" :key="kpi.row_to_json.kpi_id" cols="6" class="sparkline pa-2" style=" margin-top:auto; margin-bottom:auto; border:0px solid;")
                                            div(class="ellipsis pl-2" :title="kpi.row_to_json.text_1" style="border:0px solid;") {{ kpi.row_to_json.text_1 }}
                                            CanvasGeneric(v-if="color !== null" :values="kpi.row_to_json.values" :color="color" :label="kpi.row_to_json.text_1" style="width:100%; max-height:120px; border:0px solid;")
                                            div(class="mt-2 pl-2" style="height:145px; color:#000000; overflow:hidden; border:0px solid;")
                                                span(v-html="getFirstSentence(kpi.row_to_json.text_2)")
                                                div(@click="openInformation(kpi.row_to_json.text_1, kpi.row_to_json.text_2)" class="ml-1" style="text-decoration:underline; cursor:pointer;") {{ $t('edger_module_read_more') }}

                            div(style="position:absolute; bottom:0; width:100%;")
                                div(style="float:left; width:calc(100% - 55px); color:#000000;")
                                    div(class="bottom-right-text ml-2 mr-2 pa-1") {{ $t('edger_module_custom_kpis') }}
                                div(@click="openEdgerFinanceIR()" style="float:left; width:55px; color:#000000; cursor:pointer;")
                                    div(style="font-size:8px;") Powered by
                                    div
                                        img(src="@/assets/edger-finance-logo.png" style="width:43px;")

        PopupInformation(:openDialog="dialog" :title="title" :text="text")
</template>

<script>
import ChartPrice from "@/components/ChartPrice.vue";
import CanvasGeneric from "@/components/CanvasGeneric.vue";
import PopupInformation from "@/components/PopupInformation.vue";
import { mapGetters } from "vuex";

export default {
    name: "Overview",
    components: {
        ChartPrice,
        CanvasGeneric,
        PopupInformation,
    },
    data() {
        return {
            widthOnContainer: 0,
            width: 0,
            height: 0,
            color: null,
            dialog: false,
            title: '',
            text: '',
            topElementHeight: 0,
            bottomElementHeight: 0,
            showMe: true,
        };
    },
    mounted() {
        this.color = this.getColor();

        window.addEventListener('resize', this.onResize);

        this.onResize();

        console.log(JSON.parse(JSON.stringify(this.getCompany)));
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapGetters([
            'getCompany',
            'getShowDisplayButton'
        ]),
        kpis() {
            return this.getCompany.kpis;
        },
        getShowDisplayButtonString() {
            return JSON.stringify(this.getShowDisplayButton);
        },
    },
    watch: {
        getShowDisplayButtonString() {
            this.showMe = false;

            setTimeout(() => {
                this.showMe = true;
            }, 1);
        },
    },
    methods: {
        openEdgerFinance() {
            window.open('https://edger.finance', '_blank');
        },
        openEdgerFinanceIR() {
            window.open('https://ir.edger.finance/', '_blank');
        },
        openInformation(title, text) {
            this.dialog = true;

            setTimeout(() => {
                this.dialog = false;
            }, 200);

            console.log(title);
            console.log(text);

            this.title = title;
            this.text = text.replace(/\n/g, "<br />");
        },
        getFirstSentence(text) {
            const sentence = text.split(".")[0];

            return `${sentence}.`;
        },
        getColor() {
            const element = document.getElementsByClassName('ir-overview-main-regular')[0];
            const style = getComputedStyle(element);

            return style.color;
        },
        onResize() {
            if(document.getElementById('left-regular-chart-element') !== null) {
                const kulElement = document.getElementById('kulijul');
                const kulElement2 = document.getElementById('kulijul2');

                this.topElementHeight = kulElement.offsetHeight;
                this.bottomElementHeight = kulElement2.offsetHeight;

                const containerElement = document.getElementsByClassName('main-element')[0];
                const element = document.getElementById('left-regular-chart-element');

                this.widthOnContainer = containerElement.offsetWidth;
                this.width = element.offsetWidth;

                setTimeout(() => {
                    this.height = (element.offsetHeight <= 405) ? element.offsetHeight : 405;
                }, 1);

                this.$refs.chartPrice.updateChart();
            }
        },
        openGenericPage(page_id) {
            const element = document.getElementById(`genericMenuElement${page_id}`);

            element.click();
        },
    },
};
</script>

<style scoped>
>>> .trading-vue-data-toolbar-item {
    margin-right: 3px;
}

.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.second-step {
    display: none;
}

@media (max-width: 1130px) {
    .first-step {
        display: none;
    }

    .second-step {
        display: block;
    }
}

.fade-out-and-fade-in {
    animation: fade-out-fade-in 2s infinite;
}

@keyframes fade-out-fade-in {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>
